import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import { Pagination, Navigation } from "swiper/modules";
import Loader from "../Loaders/Loader";
import ApiNames from '../../Constants/ApiNames';
import Axios from '../../Utils/AxiosConfig';
import { NavLink, useNavigate } from "react-router-dom";

const Landing = () => {
  const swiperRef = useRef(null);
  const [activeImage, setActiveImage] = useState(0);
  const intervalRef = useRef(null);
  const [showLogo, setShowLogo] = useState(true);
  const [showHero, setShowHero] = useState(false);
  const [blogsData, setBlogsData] = useState([]);
  let navigation = useNavigate();

  const nextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const prevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const paragraphs = [
    {
      id: 1,
      title: "Bio-based Polymers",
      imageSrc: "/images/landing/invention-image-1.webp",
      content: `Discover bio-based polymers from renewable sources by harnessing renewable resources like corn starch or sugarcane, we're producing biodegradable plastics that reduce reliance on fossil fuels.
These polymers offer similar performance characteristics to traditional plastics but with a significantly reduced carbon footprint.
By shifting towards bio-based alternatives, we're actively reducing our dependence on non-renewable resources and mitigating environmental impact.`,
    },
    {
      id: 2,
      title: "Recycled Composites",
      imageSrc: "/images/landing/invention-image-2.webp",
      content: `Explore recycled composites, transforming discarded plastics and fibers into durable materials by utilizing advanced recycling techniques, we transform discarded plastics and fibers into durable composite materials for various applications.
These recycled composites find applications in automotive parts, construction materials, and consumer goods, offering a sustainable alternative to conventional virgin materials.
By closing the loop on material use, we're diverting waste from landfills and contributing to a circular economy.`,
    },
    {
      id: 3,
      title: "Bio-degradable Alternatives",
      imageSrc: "/images/landing/invention-image-3.webp",
      content: `Embrace compostable materials from plant-based sources, offering eco-friendly alternatives. Our innovation focuses on developing compostable materials derived from plant-based sources, ensuring minimal ecological footprint.
From packaging solutions to disposable utensils, our bio-degradable materials offer a viable pathway towards reducing plastic pollution and promoting sustainable consumption practices.`,
    },
    // Add more paragraphs as needed
  ];
  const maxChars = 200;

  const cards = paragraphs.reduce((acc, para, index) => {
    const cardIndex = Math.floor(index / 3); // Assuming 3 paragraphs per card
    if (!acc[cardIndex]) {
      acc[cardIndex] = [];
    }
    acc[cardIndex].push(para);
    return acc;
  }, []);

  const images = [
    "/images/landing/ani-1.webp",
    "/images/landing/ani-2.webp",
    "/images/landing/ani-3.webp",
    "/images/landing/ani-4.webp",
    "/images/landing/ani-5.webp",
  ];

  const Header = [
    "Acrylic Sheets",
    "Aluminium Composite Panels",
    "PVC Boards",
    "ABS Boards",
    "PC Multiwall & Solid Sheets",
  ];
  const Subheader = [
    "Combining transparency with sustainable strength.",
    "Eco-efficient in design and durability.",
    "Constructed for durability with eco-conscious materials.",
    "Robust, recyclable, and responsibly produced.",
    "Light-managing sheets with a lesser footprint.",
  ];

  const startAnimation = () => {
    intervalRef.current = setInterval(() => {
      setActiveImage(
        (prevActiveImage) => (prevActiveImage + 1) % images.length
      );
    }, 2000); // Change the delay as needed
  };

  // scroll images animation swiper

  const handleImageClick = (index) => {
    setActiveImage(index);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    startAnimation();
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    startAnimation();

    return () => clearInterval(intervalRef.current);
  }, [images.length]);


  //  logo animation
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogo(false);
      setShowHero(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const getBlogs = async () => {
      try {
        let api = ApiNames.getBlogs
        const response = await Axios.get(
          `${api}`
        );
        setBlogsData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getBlogs();
  }, []);
  function gotoBLogByid(data){
    navigation(`/bloginfo/${data._id}`)
  }

  return (
    <>
      <Helmet>
        <title>Just Right: Leading the Way in Sustainable Manufacturing and Innovation</title>
      </Helmet>
      <div className="landing-page">
      
          {showLogo && (
              <div className="app">
            <div className="logo-container">
              <img className="logo-img" src="/images/navbar/fav.svg" alt="Logo" />
            </div>
            </div>
          )}

          {showHero && (
            <div className="hero-section">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-md-6">
                    <div className="herosection-left">
                      <div className="herosection-leftinside">
                        <h1 className="herosection-head">Empowering</h1>
                        <h1 className="herosection-head">Sustainability,</h1>
                        <h1 className="herosection-head">Enhancing Innovation</h1>
                        <h1 className="herosection-head1">Empowering Sustainability, Enhancing Innovation</h1>
                        <p className="herosection-para">
                          Your Sustainable Choice in Reverse Logistics, Sustainable Supply Chain, and Sustainable Renewable Energy. Our products are resilient and combine performance with eco-consciousness
                        </p>
                        <ul className="herosection-btns">
                          <li>
                            <NavLink className="left-btn" to="/our-products">
                              Discover Product Suite
                              <img className="right-icon" src="/images/landing/right.svg" alt="right-data" />
                            </NavLink>
                          </li>
                          <li >
                            <NavLink className="right-btn" to="/about-us">        Meet the Team
                            <i className="fa fa-arrow-right cstmarw" aria-hidden="true"></i></NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="herosection-right">
                      <img className="power-imges" src="/images/landing/hero.webp" alt="Hero" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
       

        <div className="partner-sections">
          <h1 className="partner-head">We’ve partnered with top companies</h1>
          <div className="ourservices-data">
            <div className="container">
              <Swiper
                direction={"horizontal"}
                centeredSlides={false}
                pagination={false}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
                grabCursor={true}
                freeMode={true}
                spaceBetween={50}
                loop={true}
                scrollbar={{ draggable: true }}
                dir="ltr"
              >
                <SwiperSlide>
                  <img
                    src="/images/landing/1.jpg"
                    className="card-img-top service-image"
                    alt="no-data"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/images/landing/2.jpg"
                    className="card-img-top service-image"
                    alt="no-data"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/images/landing/3.jpg"
                    className="card-img-top service-image"
                    alt="no-data"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/images/landing/4.jpg"
                    className="card-img-top service-image"
                    alt="no-data"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/images/landing/5.jpg"
                    className="card-img-top service-image"
                    alt="no-data"
                  />
                </SwiperSlide>
              
              </Swiper>
            </div>
          </div>
        </div>
        <div className="ourmission-section">
          <div className="container">
            <div className="mission-section">
              <div className="mission-content">
                <h3 className="mission-title">Our Mission</h3>
                <h3 className="mission-subtitle">
                  Sustainable Eco-conscious Manufacturing
                </h3>
                <p className="mission-para">
                  In a world where every choice matters, Just Right stands at
                  the forefront of eco-conscious manufacturing. Our mission at
                  Just Right integrates our core capabilities in signage,
                  advertising, building materials solutions with a profound
                  commitment to environmental stewardship.
                </p>
                <p className="mission-para mission-para1">
                  {" "}
                  We're dedicated to harmonising advanced technology with
                  eco-friendly practices, ensuring our diverse product offerings
                  exceed quality and innovation standards while contributing
                  positively to the planet's well-being.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="repurpose-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="purpose-left">
                  <div className="purpose-leftinside">
                    <h3 className="repurpose-title">Repurposing with Purpose</h3>
                    <p className="repurpose-para">
                      Our commitment to the planet is embodied in our repurposing
                      initiative—transforming waste into worth. We ensure that
                      every reclaimed material finds its place in the world again,
                      not as waste, but as a useful product.
                    </p>
                    <p className="repurpose-para repurpose-para1">
                      Through relevant partnerships, we're creating meaningful
                      applications that contribute to a circular economy! With
                      Just Right, the end of one product lifecycle can be the
                      beginning of another.
                    </p>
                    <NavLink className="repurpose-btn" to="/sustainability-Initiatives">
                      Learn how we repurpose{" "}
                      <img
                        className="right-icon"
                        src="/images/landing/right.svg"
                        alt="right-data"
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="purpose-right">
                  <div className="purpose-rightinside">
                    <img
                      className="purpose-image"
                      src="/images/landing/purpose-image.webp"
                      alt="repurpose-data"
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="ecofrendly-section">
          <div className="ecofrendly-topsection">
            <div className="container">
              <div className="ecofrendly-sectioninside">
                <div className="ecofrendly-content">
                  <h3 className="ecofrendly-title">
                    Featured Eco-Friendly Products
                  </h3>
                  <h3 className="ecofrendly-subtitle">
                    Innovate Green with Just Right.
                  </h3>
                  <p className="ecofrendly-para">
                    Our manufacturing practices are sustainable, using recycled
                    materials and water for reduced gas emission
                  </p>
                </div>
              </div>

              <div className="image-box">
                {images.map((src, index) => (
                  <div
                    key={index}
                    className={`imgee-top ${activeImage === index ? "active" : ""
                      }`}
                    onClick={() => handleImageClick(index)}
                  >
                    <img className={`imges${activeImage === index ? "active" : ""
                      }`} src={src} alt={`image-${index}`} />
                    <div
                      className={`content-box ${activeImage === index ? "active" : ""
                        }`}
                    >
                      <div className="content-data">
                        <h1 className="box-headr">{Header[index]}</h1>
                        <p className="box-para">{Subheader[index]}</p>
                        <p className="learn-moree">
                          Learn More
                          <i className="fa-solid fa-arrow-right arow-right"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="swiper-top d-md-none">
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={1}
                  autoplay={false}
                  loop={true}
                  navigation={true}
                >
                  {images.map((src, index) => (
                    <SwiperSlide key={index}>
                      <div className="responsive-swipertop">
                        <img
                          className="imgess"
                          src={src}
                          alt={`image-${index}`}
                        />
                        <div className="content2-box">
                          <div>
                            <h1 className="box2-headr">{Header[index]}</h1>
                            <p className="box2-para">{Subheader[index]}</p>
                            <a className="learn2-moree" href="">
                              Learn More
                              <i className="fa-solid fa-arrow-right arow-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        {/* sustainable */}
        <div className="repurpose-section">

          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="purpose-right">
                  <div className="purpose-rightinside">
                    <img
                      className="purpose-image"
                      src="/images/landing/sustainable-image.webp"
                      alt="suatainable-data"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="purpose-left">
                  <div className="purpose-leftinside purpose-leftinside1">
                    <h3 className="repurpose-title">Sustainability Commitment</h3>
                    <p className="repurpose-para">
                      Our commitment to the planet is embodied in our repurposing
                      initiative—transforming waste into worth. We ensure that
                      every reclaimed material finds its place in the world again,
                      not as waste, but as a useful product.
                    </p>
                    <p className="repurpose-para repurpose-para1">
                      Through relevant partnerships, we're creating meaningful
                      applications that contribute to a circular economy! With
                      Just Right, the end of one product lifecycle can be the
                      beginning of another.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* success stories */}

        <div className="stories-section">
          <div className="container">
            <div className="stories-topsection">
              <div className="stories-content">
                <h3 className="stories-title">Success Stories</h3>
                <h3 className="stories-subtitle">
                  Hear from those who've joined us in making a difference.
                </h3>
                <p className="stories-para">
                  From storefronts that shine brighter with energy-efficient
                  LEDs to outdoor signage built to last with recycled materials,
                  our products are not just purchases; they're partnerships in
                  sustainability. We aimed to reduce carbon emissions and
                  overall environmental impact. For this, we invested in
                  renewable energy sources and optimized energy usage through
                  efficient equipment and processes.
                </p>
                <p className="stories-para stories-para1">
                  {" "}
                  Through closed-loop recycling,  we achieved substantial
                  reductions in waste sent to landfills and minimized the
                  extraction of raw materials. This circular economy approach
                  not only improved resource efficiency but also positioned us
                  as a leader in sustainable manufacturing within the industry.
                </p>
                <NavLink className="stories-btn" to="/sustainability-Initiatives#successstory">
                  Read More{" "}
                  <img
                    className="right-icon"
                    src="/images/landing/right.svg"
                    alt="right-data"
                  />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        {/* reportsheet-section */}
        <div className="reportsheet-section">
          <div className="container">
            <ul className="reportsheet-ul">
              <li className="reportsheet-li">
                <div className="reportsheet-content">
                  <h3 className="reportsheet-title">108+</h3>
                  <p className="reportsheet-para">Sheets sold</p>
                </div>
              </li>
              <li className="reportsheet-li">
                <div className="reportsheet-content">
                  <h3 className="reportsheet-title">30%</h3>
                  <p className="reportsheet-para">Carbon burnt</p>
                </div>
              </li>
              <li className="reportsheet-li">
                <div className="reportsheet-content">
                  <h3 className="reportsheet-title">47k</h3>
                  <p className="reportsheet-para">Clients satisfied</p>
                </div>
              </li>
              <li className="reportsheet-li">
                <div className="reportsheet-content">
                  <h3 className="reportsheet-title">47700+</h3>
                  <p className="reportsheet-para">Sheets sold</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* review-section */}
        <div className="review-section">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                {/* <div className="swiper-container"> */}
                <div className="review-left">
                  <Swiper
                    ref={swiperRef}
                    direction="horizontal"
                    centeredSlides={false}
                    pagination={false} // Remove default pagination
                    autoplay={{
                      delay: 6500,
                      disableOnInteraction: false,
                    }}
                    breakpoints={{
                      640: { slidesPerView: 1, spaceBetween: 50 },
                      768: { slidesPerView: 1, spaceBetween: 40 },
                      1024: { slidesPerView: 1, spaceBetween: 50 },
                    }}
                    className="mySwiper"
                    grabCursor={true}
                    freeMode={true}
                    dots={false}
                    loop={true}
                    spaceBetween={50}
                    scrollbar={{ draggable: true }}
                    dir="ltr"
                  >
                    <SwiperSlide>
                      <div className="topcarousel-data">
                        <div className="row justify-content-between">
                          <div className="col-md-12 d-flex align-items-center">
                            <div className="painting-left">
                              <div className="printing-para">
                                <p className="inside-paradata">
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                </p>
                              </div>
                              <h3 className="painting-title">
                                The transparency and durability of AcryloShine's acrylic sheets have revolutionized our display designs, ensuring crystal-clear visibility and resilience against outdoor elements.
                              </h3>
                              <p className="painting-discription">
                                Ali Mahmoud
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="topcarousel-data">
                        <div className="row justify-content-between">
                          <div className="col-md-12 d-flex align-items-center">
                            <div className="painting-left">
                              <div className="printing-para">
                                <p className="inside-paradata">
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                </p>
                              </div>
                              <h3 className="painting-title">
                                Multi wall panels have redefined our architectural projects, providing exceptional clarity and strength that withstands diverse weather conditions, creating striking visual impact effortlessly.
                              </h3>
                              <p className="painting-discription">
                                Rajesh Patel
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="topcarousel-data">
                        <div className="row justify-content-between">
                          <div className="col-md-12 d-flex align-items-center">
                            <div className="painting-left">
                              <div className="printing-para">
                                <p className="inside-paradata">
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                </p>
                              </div>
                              <h3 className="painting-title">
                              The durability and sleek finish of JustRight's aluminium composite panels have elevated our architectural projects, delivering enduring elegance and robustness in any environment.                              </h3>
                              <p className="painting-discription">
                              Benjamin Clarke
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="topcarousel-data">
                        <div className="row justify-content-between">
                          <div className="col-md-12 d-flex align-items-center">
                            <div className="painting-left">
                              <div className="printing-para">
                                <p className="inside-paradata">
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                </p>
                              </div>
                              <h3 className="painting-title">
                              The versatility and aesthetic appeal of JustRight’s laminates have redefined our interior spaces, offering superior wear resistance and style that stands out.                              </h3>
                              <p className="painting-discription">
                              Laura Chen
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="topcarousel-data">
                        <div className="row justify-content-between">
                          <div className="col-md-12 d-flex align-items-center">
                            <div className="painting-left">
                              <div className="printing-para">
                                <p className="inside-paradata">
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                  <span>
                                    <i className="fa-solid fa-star"></i>
                                  </span>
                                </p>
                              </div>
                              <h3 className="painting-title">
                              The superior transparency and strength of JustRight’s PET sheets have revolutionized our packaging solutions, ensuring product visibility and protection against the elements.                              </h3>
                              <p className="painting-discription">
                              Marcus Reynolds
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                    <div className="custom-swiper-buttons">
                      <button
                        className="swiper-button-prev"
                        onClick={prevSlide}
                      ></button>
                      <button
                        className="swiper-button-next"
                        onClick={nextSlide}
                      ></button>
                    </div>
                  </Swiper>
                </div>
              </div>
              <div className="col-md-5">
                <div className="review-right">
                  <img
                    className="scroll-images"
                    src="/images/landing/review-image.webp"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="inventions-section">
          <div className="container">
            <div className="latestinvention-topsection">
              <ul className="latestinvention-ul">
                <li className="latestinvention-li">
                  <div className="latestinvention-content">
                    <h3 className="latestinvention-title">Blog</h3>
                    <p className="latestinvention-para">
                    Our commitment to research and development means we're always on the cusp of the next eco-friendly breakthrough.
                    </p>
                  </div>
                </li>
                <li className="latestinvention-li">
                  <NavLink className="viewall-btn" to="/allblogs">
                    View all{" "}
                        <i className="fas fa-arrow-right right-icon"></i>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="row">
            {blogsData.slice(0,4).map((products, index) => (
                <div key={index} className="col-md-3">
                  <div className="card invention-items" onClick={()=>gotoBLogByid(products)}>
                    <div className="inventionitem-image">
                      <img
                        src={products?.image}
                        className="card-img-top image-data"
                        alt="Card"
                      />
                    </div>
                    <div className="card-body invention-body">
                      <ul className="invention-card-ul">
                        <li className="invention-card-li">
                          <p className="invention-card-title">{products?.title.slice(0,60)}</p>
                        </li>
                        <li className="invention-card-li">
                          <i className="fas fa-arrow-right right-icon"></i>
                        </li>
                      </ul>
                   
                        <p className="invention-card-para">
                          {products?.description.slice(0,120)}
                        </p>
                 
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Landing;
