import React, { useEffect } from "react";
import Newsletter from "../Newsletter/Newsletter";
import { Helmet } from "react-helmet";

const Latestinnovations = () => {

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Innovating for a Sustainable Tomorrow
        </title>
      </Helmet>
      <div className="innovations-content">
        <div className="container">
          <div className="innovation-hero">
            <h1 className="innovation-head">
              Breaking ground in sustainability-geared technology innovations
            </h1>
            <p className="innovate-para">
              At Just Right, our latest innovations combine modern technology
              with trending designs to create products that reflect our brand
              values.
            </p>
            <p className="ahead-para">
              We keep ahead of the curve by partnering with leading
              manufacturers and innovators to provide you with the latest
              materials and technologies.
            </p>
          </div>
        </div>
        {/* ======================= Let your brand stand out in style============= */}
        <div className="innovat-data">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="inovation-left-top">
                  <div className="innovat-content">
                    <h1 className="smart-head">Smart Signage Products</h1>
                    <h1 className="innovat-head">
                      Let your brand stand out in style
                    </h1>
                    <p className="innovat-commitmentpara">
                      Discover how digital signage is transforming traditional
                      advertising with dynamic, interactive displays that capture
                      attention and engage audiences like never before.
                    </p>
                    {/* <button className="innovat-btn">
                      Learn more about our products
                      <i className="fas fa-arrow-right cstmarw"></i>
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="innovat-image">
                  <img
                    className="innovat-img"
                    src="/images/innovations/image-1.webp"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="innovat-data">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="innovat-image">
                  <img
                    className="innovat-img"
                    src="/images/innovations/image-2.webp"
                  ></img>
                </div>
              </div>
              <div className="col-md-6">
                <div className="inovation-left-top ">
                  <div className="innovat-content">
                    <h1 className="smart-head">
                      Next-Generation Construction Materials
                    </h1>
                    <h1 className="innovat-head">
                      Explore our diverse range of next-generation construction
                      materials
                    </h1>
                    <p className="innovat-commitmentpara">
                      From sustainable construction materials to groundbreaking
                      construction techniques, learn about the latest advancements
                      that are shaping the future of architecture and
                      infrastructure.
                    </p>
                    {/* <button className="innovat-btn">
                      Learn more about our products
                      <i className="fas fa-arrow-right cstmarw"></i>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===================== How Are We Innovating? ====================== */}
        <div className="Innovating">
          <h1 className="innovating-data">How Are We Innovating?</h1>
          <h1 className="chemical-head">Chemical Recycling Facility</h1>
          <p className="subsidi-para">
          Through a collaboration between our subsidiary, Renov8 Polymer Industries we’ve established an advanced chemical recycling facility. 
          </p>
          <p className="movewill-para">
            The move will boost Abu Dhabi’s transition towards a circular
            economy.
          </p>
          {/* <button className="innov-btn">
            Learn more
            <span>
              <i className="fa-solid fa-arrow-right innovarrow-i"></i>
            </span>
          </button> */}
        </div>
        <div className="container">
          <div className="chemical-img">
            <img
              className="recyle-image"
              src="/images/innovations/image-3.webp"
            ></img>
          </div>
        </div>
        <Newsletter />
      </div>
    </>
  );
};

export default Latestinnovations;
