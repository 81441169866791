import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";


const Header = () => {

  const [navMenuActive, setNavMenuActive] = useState(false);





  const handleHamburgerClick = () => {
    setNavMenuActive(!navMenuActive);
  };

  let clickNav = useRef(null);
  function closemenu() {
    if (clickNav.current) {
      clickNav.current.click()
    }
  }

  return (
    <header >
      <nav
        className={`navbar navbar-expand-lg navbar-light TopnavBar-Section p-2`}
      >
        <div id="progress-barss"></div>
        <div className="container">
          <NavLink className="navbar-brand logo-section" to="/landing" >
            <img
              className="main-logo"
              src='/images/navbar/logo.svg'
              loading="eager"
              alt="no-data"
            />
          </NavLink>
          <form className="form-inline d-block d-lg-none">
            <NavLink className="btn login-txt"  to="/contact-us" >
            Contact Us
            </NavLink>

          </form>
          <div
            ref={clickNav}
            className={`hamburger-edit ${navMenuActive ? "active" : ""}`}
            onClick={handleHamburgerClick}
          >
            <span className="bar" />
            <span className="bar" />
            <span className="bar" />
          </div>
          <div
            className={`navbar-collapse navbarTop-colapse ${navMenuActive ? "active" : ""
              }`}
          >
            <ul className="navbar-nav mr-auto navbarTopUl-edit p-2">

              <li className="nav-item">
                <NavLink className="nav-link navlink-mainlist" to={"/about-us"} onClick={closemenu}>
                About Us
                <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink className="nav-link navlink-mainlist" to={"/our-products"} onClick={closemenu}>
                Our Products
                <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link navlink-mainlist" to={"/sustainability-Initiatives"} onClick={closemenu}>
                Sustainability Initiatives
                <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i>
                </NavLink>
              </li>
          
              <li className="nav-item">
                <NavLink className="nav-link navlink-mainlist" to={"/latest-innovations"} onClick={closemenu}>
                Lastest Innovations
                <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink className="nav-link navlink-mainlist" to={"/faq"} onClick={closemenu}>
                FAQ
                <i className="fa fa-arrow-right icon-lists" aria-hidden="true" ></i>
                </NavLink>
              </li>




            </ul>
            <form className="form-inline d-none d-lg-block">
              <NavLink
                className="btn login-txt"
                type="button"
                to="/contact-us"
              >
                Contact Us
              </NavLink>

            </form>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
